(function () {
  'use strict';

  class ResultsExpandedCtrl {
    constructor($mdDialog, ResultsExpanded, Modules) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Modules = Modules;
      vm.obj = ResultsExpanded.getObj();
      vm.init();
    }
    init() {
      const vm = this;
      vm.Modules.discussionEntries({id: vm.obj.module_sessions.id, group_id: vm.obj.group.id})
        .then((data) => {
          vm.discussionsPayload = data;
        });
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:ResultsExpandedCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ResultsExpandedCtrl', ResultsExpandedCtrl);
}());
